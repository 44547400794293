import { useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import axiosServices from "../utils/axios";

import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { BACKEND_URL } from "../utils/type";

const ProfileModal = ( { open, onClose, onConfirm } ) => {

    const { t } = useTranslation();

    const { user, updateProfile } = useAuth();
    const [ name, setName ] = useState(user?.name ?? "");
    const [ email, setEmail ] = useState(user?.email ?? "");
    const [ avatar, setAvatar ] = useState(null);

    function getImageFileObject(file) {
        setAvatar(file?.file);
    }
    
    function runAfterImageDelete(file) {

    }

    const handleProfileUpdate = async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("avatar", avatar);

        try {
            const res = await axiosServices.postForm(`user/${user?.id}?_method=PATCH`, formData);

            if(res?.data?.status === "success") {
                updateProfile(res?.data?.user);
                localStorage.setItem("auth", JSON.stringify(res?.data?.user));
                onClose();
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: "rounded-lg bg-[#FFFFFF] w-[335px] lg:w-[660px] !p-0" }}>
            <div className="flex flex-col">

                <div className="flex-grow px-[50px] pt-[50px] pb-11 w-full h-full flex flex-col gap-[30px]">
                    
                    <div className="border-b border-[#DDDDDD] relative flex">
                        <div className="border-b-[3px] border-[#952DD1] font-bold text-2xl text-center flex flex-col gap-5">
                            { t('edit_profile') }
                            <div></div>
                        </div>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-0 ltr:right-0 rtl:left-0 cursor-pointer" onClick={onClose}>
                            <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                        </svg>
                    </div>


                    <div className="flex flex-col gap-4">
                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-normal text-base w-full flex'>{ t('full_name') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#000] font-light outline-none border'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-normal text-base w-full flex'>{ t('email') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#000] font-light outline-none border rtl:text-left'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-normal text-base w-full flex'>{ t('policy_acceptance_date') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#000] font-light outline-none border'
                                value={user?.policy_date?.slice(0, 10)}
                                disabled
                            />
                        </div>

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-normal text-base w-full flex'>{ t('avatar') }</div>

                            <div className={`flex ${user?.avatar ? 'justify-between':'justify-center'}`}>
                                {
                                    user?.avatar && 
                                    <img src={`${BACKEND_URL}/storage/${user?.avatar}`} className="w-[100px] h-[100px] rounded-lg" />
                                }
                                <ImageUploader
                                    onFileAdded={(img) => getImageFileObject(img)}
                                    onFileRemoved={(img) => runAfterImageDelete(img)}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex min-h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full" 
                        onClick={() => handleProfileUpdate()}
                    >
                        { t('update') }
                    </button>
                    <span className="border-r border-[#DDDDDD]"></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ProfileModal;