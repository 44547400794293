import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

// third-party
import { Chance } from 'chance';
import { jwtDecode } from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_PROFILE, QUESTION, SET_AUTH_MODAL_OPEN, SET_SELECT_PURCHASE_MODAL_OPEN } from './auth-reducer/actions';
import authReducer from './auth-reducer/auth';

// project import
import Loader from '../components/Loader';
import axios from '../utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    questionPurchase: null, 
    authModalOpen: 0,  // 0 - close, 1 - register, 2 - verify
    selectPurchaseModalOpen: false
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */

    return decoded.exp > (Date.now() / 1000) - (60 * 60 * 3);
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const { user } = state;
    const { t } = useTranslation();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceTokenOld = window.localStorage.getItem('serviceToken');
                if (serviceTokenOld && verifyToken(serviceTokenOld)) {
                    // const decoded = jwtDecode(serviceTokenOld);
                    // const response = await axios.post('/checkOTP', {otp: decoded?.otp, phone_number: decoded?.phone_number});

                    // const { serviceToken, user } = response.data;
                    const serviceToken = localStorage.getItem("serviceToken");
                    const user = JSON.parse(localStorage.getItem("auth") || "{}");

                    setSession(serviceToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                    finishQuestion();
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    useEffect(() => {
        finishQuestion();
    }, [ user ])

    const login = async (otp, phone) => {
        try {
            const response = await axios.post('/checkOTP', { otp, phone_number: phone });
            const { serviceToken } = response.data;
            setSession(serviceToken);
            const user = JSON.parse(localStorage.getItem("auth") || "{}");

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        } catch (error) {
            return false;
        }
        return true;
    };

    const resendOtp = async (phone_number) => {
        try {
            await axios.post('/sendOTP', { phone_number });
        } catch (error) {
            console.log(error)
            return false;
        }

        return true;
    }

    const register = async (phone, countryData) => {
        // todo: this flow need to be recode as it not verified
        try {
            const response = await axios.post('register', {
                country_code: countryData?.iso2,
                phone_code: `+${countryData?.dialCode}`,
                phone_number: phone.split(`+${countryData?.dialCode}`)[1],
                phone: phone
            });
            let users = response?.data?.user;

            localStorage.setItem('auth', JSON.stringify(users));
            return response?.data;
        } catch (error) {
            return error;
        }

    };

    const logout = () => {
        setSession(null);
        localStorage.setItem("auth", null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async () => { };

    const updateProfile = (user) => {
        dispatch({
            type: UPDATE_PROFILE,
            payload: {
                user
            }
        });
    };


    const finishQuestion = async () => {

        if( !user?.id ) return;

        try {
            let qsIds = JSON.parse(localStorage.getItem("qsList") || "[]");
            const { lecture, settings } = JSON.parse(localStorage.getItem("basedata") || "{}");

            if (!qsIds || qsIds?.length === 0 || !lecture) return;

            const qsTrans = lecture?.transactions?.filter((t) => t.type === "Question");


            qsTrans.forEach(qst => {
                const ids = JSON.parse(qst?.question_ids || "[]");
                qsIds = qsIds?.filter(item => !ids.includes(item));
            });

            let questionCount = qsIds?.length;
            if (!questionCount || questionCount?.length === 0) return;

            const price = Number(Number(lecture?.course?.question_price * (Number(settings['value_increase']) / 100 + 1 - Number(lecture?.teacher?.decrease) / 100) / 100).toFixed(2));
            const amount = (questionCount * price).toFixed(2);

            const questionPurchase = {
                // title: `You answered ${questionCount} counts questions`,
                // content: `Your balance will reduce ${questionCount} * $${price} = $${amount}`,
                title: t(`you_answered__counts_questions`, {questionCount: questionCount}),
                content: t(`your_balance_will_reduce`, {questionCount: questionCount, price: price, amount: amount}),
                questionCount, 
                price, 
                amount,
            }

            dispatch({
                type: QUESTION,
                payload: { questionPurchase }
            });
        } catch (error) {
            console.log("finishQuestion", error);
        }
    }

    const reduceBalance = async () => {
        try {
            let qsIds = JSON.parse(localStorage.getItem("qsList") || "[]");
            const { lecture, settings } = JSON.parse(localStorage.getItem("basedata") || "{}");

            if (!qsIds || qsIds?.length === 0 || !lecture) return;

            const qsTrans = lecture?.transactions?.filter((t) => t.type === "Question");


            qsTrans.forEach(qst => {
                const ids = JSON.parse(qst?.question_ids || "[]");
                qsIds = qsIds?.filter(item => !ids.includes(item));
            });


            let questionCount = qsIds?.length;
            if (!questionCount || questionCount?.length === 0) return;

            const price = Number(Number(lecture?.course?.question_price * (Number(settings['value_increase']) / 100 + 1 - Number(lecture?.teacher?.decrease) / 100) / 100).toFixed(2));
            const amount = (questionCount * price).toFixed(2);

            const { data } = await axios.post('transactionApi', {
                admin_id: lecture?.admin_id,
                user_id: user?.id,
                course_id: lecture?.course_id,
                lecture_id: lecture?.id,
                type: 'Question',
                title: `Question Purchase`,
                content: `${lecture?.title}: ${questionCount} count(s) => $${amount}`,
                amount: amount,
                question_ids: `${JSON.stringify(qsIds)}`,
                questions: questionCount,
            });

            if (data?.status === "success") {
                localStorage.setItem("qsList", JSON.stringify([]));
                dispatch({
                    type: QUESTION,
                    payload: { questionPurchase: null }
                });
            }
        } catch (error) {
            console.log("finishQuestion", error);
        }
    }

    const setAuthModalOpen =  (authModalOpen) => {
        dispatch({
            type: SET_AUTH_MODAL_OPEN,
            payload: { authModalOpen }
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, resendOtp, logout, register, resetPassword, updateProfile, finishQuestion, reduceBalance, setAuthModalOpen }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
