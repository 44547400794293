import { useState, useEffect } from "react";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { PhoneNumberUtil } from 'google-libphonenumber';
import OTPInput from 'react-otp-input';
import 'react-international-phone/style.css';

import { PulseLoader } from 'react-spinners';
import useAuth from '../hooks/useAuth';

import { useTranslation } from "react-i18next";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
};

const VerifyModal = ({  }) => {

    const { t } = useTranslation();

    const localUsers = JSON.parse(localStorage.getItem('auth'));

    const { login, resendOtp, authModalOpen, setAuthModalOpen } = useAuth();
    const [otp, setOtp] = useState('');
    const [status, setStatus] = useState(true);
    const isValid = otp.length === 5;

    const [loading, setLoading] = useState(false);
    const [ count, setCount ] = useState(60);
    const [ remain, setRemain ] = useState("00:00");

    const onClose = () => {
        setAuthModalOpen(0);
    }

    useEffect(() => {
        if(count > 0) {
            setTimeout(() => {
                setCount((old) => (old - 1));
            }, 1000);
        }

        // Calculate hours, minutes, and remaining seconds
        const minutes = Math.floor(count / 60);
        const seconds = Math.floor(count % 60);

        // Pad hours and minutes with leading zeros if necessary
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
        setRemain(`${formattedMinutes}:${formattedSeconds}`);
      }, [ count ]);

    return (
        <Modal open={authModalOpen === 2} onClose={onClose} center showCloseIcon={false} classNames={{ modal: " rounded-lg !bg-[#FFFFFF] w-[335px] min-h-[450px] lg:min-w-[420px] lg:w-[420px] lg:min-h-[460px] !p-0" }} >
            <div className="flex flex-col gap-6 p-[50px]">

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-6 cursor-pointer" onClick={onClose}>
                    <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                </svg>

                <div className="w-[100px] h-[100px] bg-[#942CC71A] rounded-full mx-auto flex items-center justify-center">
                    <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4V36H24V4H4ZM2 0H26C27.1046 0 28 0.89544 28 2V38C28 39.1046 27.1046 40 26 40H2C0.89544 40 0 39.1046 0 38V2C0 0.89544 0.89544 0 2 0ZM14 30C15.1046 30 16 30.8954 16 32C16 33.1046 15.1046 34 14 34C12.8954 34 12 33.1046 12 32C12 30.8954 12.8954 30 14 30Z" fill="#942CC7"/>
                    </svg>
                </div>
                
                <div className=" text-xl font-bold text-[#000] text-center">{t('please_enter_verification_code')}</div>
                <div className="text-base font-light text-[#000] text-center">{t('we_have_sent_an_sms_with_an_activation_code_to_your_phone')} {`${localUsers?.phone_code} ${localUsers?.phone_number}`}</div>

                <div className='flex flex-col gap-1'>
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                            'justifyContent': 'space-between', 
                            'direction': 'ltr'
                        }}
                        inputStyle={{
                            'width': '51px', 
                            'height': '55px', 
                            'borderRadius': '8px', 
                            'color': '#060606', 
                            'fontWeight': '500', 
                            'fontSize': '16px', 
                            'outline': 'none',
                            'borderColor': '#952DD1', 
                            'borderWidth': '1px'
                        }}
                    />
                </div>

                <div className="">
                    <div className='flex justify-between'>
                        {
                            !status && 
                            <span className='text-[#FF6868]'>{t('otp_invalid_or_expired')}</span>
                        }
                        {
                            status && 
                            <span></span>
                        }
                        {
                            !count && 
                            <a 
                                className='text-[#952DD1] cursor-pointer' 
                                onClick={ async () => {
                                    setOtp("");
                                    const res = await resendOtp(`${localUsers?.phone_code}${localUsers?.phone_number}`);
                                    setStatus(true);
                                    setCount(60);
                                }}
                            > {t('resend_otp')} </a>
                        }
                    </div>
                    {
                        !!count && 
                        <div className='text-[#716F74] text-center mt-2'>{t('you_can_request_a_new_code_in')} {remain}</div>
                    }
                </div>


                <button 
                    className='bg-[#160F41] h-[50px] text-white leading-6 font-normal text-[16px] rounded-lg disabled:bg-opacity-60 flex justify-center items-center' 
                    disabled={!isValid || loading}
                    onClick={async () => {
                        setLoading(true);
                        const state = await login(otp, `${localUsers?.phone_code}${localUsers?.phone_number}`);
                        setLoading(false);
                        setStatus(state);
                        if( state ) {
                            setAuthModalOpen(0);
                        }
                    }}
                >
                    {
                        loading ? 
                        <PulseLoader
                            loading={loading}
                            color='#FFFFFF'
                            size={12}
                        />
                        :
                        t('verify_otp')
                    }
                </button>

            </div>
        </Modal>
    )
}

export default VerifyModal;