import axios from 'axios';
import * as types from './type';
import moment from 'moment';

const axiosServices = axios.create({ baseURL: `${types.BACKEND_URL}/api` });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
    async (config) => {
        const accessToken = localStorage.getItem('serviceToken');
        const locale = localStorage.getItem("locale") || "en";

        const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        config.headers['locale'] = locale;
        config.headers['localTime'] = currentDateTime;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/register')) {
        localStorage.removeItem("auth");
        localStorage.removeItem('serviceToken');
        window.location = '/home?login=1';
    }

    return Promise.reject((error?.response && error?.response?.data) || 'Wrong Services');
  }
);

export default axiosServices;

export const fetcher = async (args) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosServices.get(url, { ...config });
    
    return res.data;
};

export const fetcherPost = async (args) => {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosServices.post(url, { ...config });

    return res.data;
};
