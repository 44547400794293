import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import AuthLayout from "../layout/auth";
import DashboardLayout from "../layout/dashboard";
import Loadable from "../components/Loadable";

const RegisterPage = Loadable(lazy(() => import("../pages/auth/register")));
const VerifyPage = Loadable(lazy(() => import("../pages/auth/verify")));

const HomePage = Loadable(lazy(() => import("../pages/home")));
const CoursePage = Loadable(lazy(() => import("../pages/home/course")));
const LecturesPage = Loadable(lazy(() => import("../pages/home/lectures")));
const LecturePage = Loadable(lazy(() => import("../pages/home/lecture")));

const BillingPage = Loadable(lazy(() => import("../pages/billing")));
const CardPage = Loadable(lazy(() => import("../pages/billing/card")));

const MyBalancePage = Loadable(lazy(() => import("../pages/mybalance")));
const PromoCodePage = Loadable(lazy(() => import("../pages/promocode")));
const AddNewPromoCodePage = Loadable(lazy(() => import("../pages/promocode/add")));


export const router = createBrowserRouter([
    // {
    //     path: '/', 
    //     element: <Navigate to={"/register"} />
    // },
    // {
    //     path: '/', 
    //     children: [
    //         {
    //             path: '/', 
    //             element: <AuthLayout />, 
    //             children: [
    //                 {
    //                     path: 'register', 
    //                     element: <RegisterPage />
    //                 }, 
    //                 {
    //                     path: 'verify', 
    //                     element: <VerifyPage />
    //                 }
    //             ]
    //         }
    //     ]
    // }, 
    {
        path: '/', 
        children: [
            {
                path: '/', 
                element: <DashboardLayout />, 
                children: [
                    {
                        path: '', 
                        element: <HomePage />
                    },
                    {
                        path: 'home', 
                        element: <HomePage />
                    }, 
                    {
                        path: 'home/:subject_id', 
                        element: <CoursePage />
                    }, 
                    {
                        path: 'home/:subject_id/:course_id', 
                        element: <LecturesPage />
                    }, 
                    {
                        path: 'home/:subject_id/:course_id/:page', 
                        element: <LecturePage />
                    }, 
                    {
                        path: 'billing', 
                        element: <BillingPage />
                    }, 
                    {
                        path: 'billing/card', 
                        element: <CardPage />
                    }, 
                    {
                        path: 'billing/card/:card_id', 
                        element: <CardPage />
                    }, 
                    {
                        path: 'mybalance', 
                        element: <MyBalancePage />
                    }, 
                    {
                        path: 'promocode', 
                        element: <PromoCodePage />
                    }, 
                    {
                        path: 'promocode/add', 
                        element: <AddNewPromoCodePage />
                    }, 
                    {
                        path: 'promocode/add/:promo_id', 
                        element: <AddNewPromoCodePage />
                    }, 
                ]
            }
        ]
    }
]);