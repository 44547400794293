import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";

import useAuth from "../hooks/useAuth";
import axiosServices from "../utils/axios";
import { ENVIRONMENT, PRICE_LIST, PAYPAL_CLIENT_ID } from "../utils/type";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GooglePayButton from '@google-pay/button-react';


const ConnectCardModal = ({ open, onClose, priceIndex }) => {

    const { lectureData } = useSelector((state) => state.data);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [ loading, setLoading ] = useState(0);
    const [state, setState] = useState({
        number: user?.card?.card_number || '',
        expiry: user?.card?.expiry_date || '',
        cvc: '',
        name: user?.card?.card_name || '',
        focus: '',
    });

    const [initialOptions, setInitialOptions] = useState({
        "client-id": PAYPAL_CLIENT_ID, 
        "currency": "USD",
        "intent": "capture",
        "environment": ENVIRONMENT, 
        "components": "buttons,googlepay"
    });

    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [googlePayConfig, setGooglePayConfig] = useState(null);


// MARK: - Google Pay

    useEffect(() => {
        const fetchGooglePayConfig = async () => {
            const response = await axiosServices.get('/transactionApi/config');
            setGooglePayConfig(response.data);
        };

        if( user?.id ) {
            fetchGooglePayConfig();
        }
    }, [ user ]);


    const onLoadPaymentData = async (paymentData) => {
        console.log(paymentData)
        // try {
        //   const orderId = await createOrder();
    
        //   const confirmResponse = await axios.post(`/api/orders/${orderId}/confirm`, {
        //     paymentMethodData: paymentData.paymentMethodData
        //   });
    
        //   if (confirmResponse.data.status === 'PAYER_ACTION_REQUIRED') {
        //     await handlePayerAction(orderId);
        //   }
    
        //   const captureResponse = await axios.post(`/api/orders/${orderId}/capture`);
        //   setPaymentResult(captureResponse.data);
    
        //   setModalVisible(true);
        // } catch (error) {
        //   console.error('Error processing payment:', error);
        // }
    };















    const createOrder = async () => {
        try {
            const response = await axiosServices.post('/transactionApi/createOrder', { amount: PRICE_LIST[priceIndex] });
            return response.data;
        } catch (error) {
            setErrorMessage('Error creating order');
            return false;
        }
    };

    const onApprove = async (data, actions) => {
        try {
            const response = await axiosServices.post('/transactionApi/captureOrder', { orderId: data.orderID });
            console.log(response)
            if(response?.data?.status === "COMPLETED") {
                onClose("success");
            }
        } catch (error) {
            setErrorMessage('Error capturing order');
        }
    };

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: "rounded-[30px] bg-[#F7F6FD] !p-0 w-[335px] lg:w-[660px] h-[350px] lg:h-[400px] !m-0" }} >

            <div className="flex flex-col gap-6 justify-between h-full">
                <div className="flex-grow px-10 lg:px-[50px] pt-[50px] w-full h-full flex flex-col gap-5 lg:gap-[30px]">

                    <div className="border-b border-[#DDDDDD] relative flex">
                        <div className="border-b-[3px] border-[#952DD1] font-normal text-xl lg:text-2xl text-center flex flex-col gap-4 lg:gap-5">
                            { `${t(`amount`)} : $${ PRICE_LIST[priceIndex] }`}
                            <div></div>
                        </div>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-0 ltr:right-0 rtl:left-0 cursor-pointer" onClick={onClose}>
                            <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                        </svg>
                    </div>

                    <div className="flex-grow flex flex-col">

                        <PayPalScriptProvider options={initialOptions}>
                            <div>
                                {success ? (
                                <div>
                                    <h2>{t(`payment_successful`)}</h2>
                                </div>
                                ) : (
                                <div>
                                    <PayPalButtons
                                        createOrder={createOrder}
                                        onApprove={onApprove}
                                        onError={(e) => console.log(e.message)}
                                    />
                                    
                                    {errorMessage && <div>{errorMessage}</div>}
                                </div>
                                )}
                            </div>
                        </PayPalScriptProvider>
                        <GooglePayButton
                            environment="PRODUCTION" // Change to 'PRODUCTION' for live transactions
                            paymentRequest={{
                                apiVersion: googlePayConfig?.apiVersion,
                                apiVersionMinor: googlePayConfig?.apiVersionMinor,
                                allowedPaymentMethods: googlePayConfig?.allowedPaymentMethods,
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: `${PRICE_LIST[priceIndex]}`,
                                    currencyCode: 'USD', 
                                    countryCode: 'US',
                                },
                                merchantInfo: googlePayConfig?.merchantInfo,
                                callbackIntents: ['PAYMENT_AUTHORIZATION']
                            }}
                            onLoadPaymentData={onLoadPaymentData}
                            onPaymentAuthorized={() => ({ transactionState: 'SUCCESS' })}
                            existingPaymentMethodRequired="true"
                            buttonColor="black"
                            buttonType="buy"
                            className="w-full [&>div>button]:w-full"
                            onError={(err) => console.log(err)}
                        />

                    </div>

                </div>
 
                <div className="flex min-h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full" 
                        // onClick={handlePayConfirm}

                    >
                        { t('confirm') }
                    </button>
                    <span className="border-r border-[#DDDDDD]"></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ConnectCardModal;