import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";

const BillCompleteModal = ({ open, onClose }) => {

    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: "rounded-[30px] !bg-[#FFFFFF] w-[330px] lg:min-w-[330px] lg:w-[330px] h-auto !p-0" }} >
            <div className="flex flex-col justify-between h-full">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-5 right-5 cursor-pointer" onClick={onClose}>
                    <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                </svg>

                <div className="flex-grow flex flex-col items-center justify-center gap-5 my-14 px-[30px]">
                    <div className={`text-xl font-bold ${open % 2 === 1 ? "text-[#5EC72C]" : "text-[#FF6826]"} text-center`}>
                        {
                            open % 2 === 1 ? t('successful') : t('unsuccessful')
                        }
                    </div>

                    {
                        open === 1 && 
                        <div className="flex flex-col items-center justify-center text-center">
                            <div className="text-base font-light">{t('your_bill_was_successfully')}</div>
                        </div>
                    }

    {
                        open === 3 && 
                        <div className="flex flex-col items-center justify-center text-center">
                            <div className="text-base font-light">{t('your_purchase_was_successful')}</div>
                        </div>
                    }

                    {
                        open === 2 && 
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-base font-light">{t('we_were_unable_to_charge_your_card')}</div>
                            <div className="text-base font-light">{t('please_re_check_billing_information')}</div>
                        </div>
                    }

                    {
                        open === 4 && 
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-base font-light text-center">{t('you_should_add_a_card_and_set_the_default_card')}</div>
                        </div>
                    }

    {
                        open === 6 && 
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-base font-light text-center">{t('your_purchase_was_unsuccessful')}</div>
                        </div>
                    }
                </div>

                <div className="flex h-[70px] border-t border-[#DDDDDD]">
                    <span className={`${open % 2 === 1 ? "border-[#5EC72C]" : "border-[#FF6826]"} border-t-[5px] w-32 absolute -mt-1`}></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('close') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default BillCompleteModal;